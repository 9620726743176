/* source-sans-pro-regular - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src:
		local(''),
		url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.ttf')
			format('truetype'); /* Safari, Android, iOS */
}

/* source-sans-pro-600 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src:
		local(''),
		url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.ttf')
			format('truetype'); /* Safari, Android, iOS */
}

/* source-sans-pro-700 - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src:
		local(''),
		url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.ttf')
			format('truetype'); /* Safari, Android, iOS */
}
