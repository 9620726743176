@import "/home/runner/work/digiSucht-frontend/digiSucht-frontend/src/resources/styles/settings.scss"; @import "/home/runner/work/digiSucht-frontend/digiSucht-frontend/src/extensions/resources/styles/settings.scss";  .spinner {
	width: 60px;
	height: 60px;
	z-index: 300;
	position: relative;
	opacity: 1;

	.double-bounce1,
	.double-bounce2 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: $white;
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;

		animation: sk-bounce 2s infinite ease-in-out;
	}

	.double-bounce2 {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}

	&.dark {
		.double-bounce1,
		.double-bounce2 {
			background-color: $black-10-opacity;
		}
	}

	@keyframes sk-bounce {
		0%,
		100% {
			transform: scale(0);
			-webkit-transform: scale(0);
		}
		50% {
			transform: scale(1);
			-webkit-transform: scale(1);
		}
	}
}
