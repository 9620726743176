@import "/home/runner/work/digiSucht-frontend/digiSucht-frontend/src/resources/styles/settings.scss"; @import "/home/runner/work/digiSucht-frontend/digiSucht-frontend/src/extensions/resources/styles/settings.scss";  $input-height: 48px;
$select-menu-before-display: block !default;

.select {
	&__wrapper {
		margin: $grid-base-two auto 0;
		max-width: $max-input-width;
		text-align: center;

		.warning {
			margin-top: 10px;
			color: $form-error;
		}
	}

	&__label {
		cursor: pointer;
		font-size: $font-size-primary;
		color: $form-secondary;
		margin: 0 0 0 15px;
	}

	&__input {
		&:focus {
			outline: $focus-outline;
			outline-offset: 4px;
			border-radius: 4px;
			transition: all 0.6s ease;
		}

		&__menu {
			border-top: 1px solid rgba($black, 0.1);

			@include rounded-drop-shadow;

			&::before {
				display: $select-menu-before-display;
			}
		}
	}
}

.custom-select-container {
	position: relative;
	width: 100%;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: -14px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		left: 50%;
		margin-left: -10px;
		transform: rotate(180deg);
	}

	&:before {
		border-top: 10px solid $white;
		margin-top: 68px;
		z-index: 12;
	}

	&:after {
		border-top: 10px solid $form-disabled;
		margin-top: 67px;
		z-index: 11;
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 0.25s ease,
			visibility 0.25s ease;
	}

	.custom-select-opener {
		width: 100%;
		min-width: 220px;
		height: $input-height;
		display: block;
		border-radius: calc($input-height / 2);
		font-size: 18px;
		border: 1px solid $form-primary;
		color: $form-primary;
		cursor: pointer;
		outline: 0;
		padding: 13px 19px 0;
		box-shadow:
			0 2px 0 0 rgba(0, 0, 0, 0.2),
			0 3px 0 0 rgba(0, 0, 0, 0.1);
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-progress-appearance: none;
		background: url('../../resources/img/icons/arrow-down.svg') no-repeat
			calc(100% - 20px) center;
		background-size: 20px;
		text-align: left;
		margin-bottom: 15px;

		&:hover {
			border-color: $form-active;
			color: $form-active;
		}

		&:focus {
			border: 2px solid $form-active;
		}

		&--selected {
			color: $form-active;
		}
	}

	.custom-select-panel {
		max-height: 320px;
		overflow-x: auto;
		overflow-y: scroll;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		box-sizing: border-box;
		background: $white;
		width: 100%;
		border: 1px solid $form-disabled;

		@include rounded-drop-shadow;
		transition:
			opacity 0.25s ease,
			visibility 0.25s ease;

		.custom-select-option {
			text-align: left;
			padding: 12px $grid-base-three;
			cursor: pointer;

			&:hover {
				color: var(--skin-color-default, $primary);
				background-color: $hover-select;
			}
		}
	}

	&.is-open {
		.custom-select-panel {
			opacity: 1;
			visibility: visible;
			transition-duration: 0.25s;
		}
	}
}

.is-open {
	&:after {
		opacity: 1;
		visibility: visible;
		transition-duration: 0.25s;
	}
}
