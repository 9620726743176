@import "/home/runner/work/digiSucht-frontend/digiSucht-frontend/src/resources/styles/settings.scss"; @import "/home/runner/work/digiSucht-frontend/digiSucht-frontend/src/extensions/resources/styles/settings.scss";  $inputHeight: 50px;

.select {
	&__wrapper {
		margin: $grid-base-four auto $grid-base-two;
		max-width: $max-input-width;
		width: 100%;

		@include breakpoint($fromLarge) {
			margin: 0 auto $grid-base-two;
		}

		.inputField__wrapper {
			margin: 0 auto;
		}

		.formWrapper__inputWrapper {
			margin-left: 0;
		}
	}

	&__inputWrapper {
		height: $inputHeight;
		padding: 15px 0;
	}

	&__input {
		display: block;
		width: 100%;
		margin: auto 0;
		cursor: pointer;

		&__iconWrapper {
			width: $grid-base-three;
			height: $grid-base-three;
		}

		&__control {
			border-radius: $select-dropdown-border-radius;
			cursor: pointer;
		}

		&__option {
			background-color: $white;
			color: var(--skin-color-default, $secondary);
			min-height: 48px;

			// The `!important` annotation is relevant to not be overridden by inline styles.
			display: flex !important;
			align-items: center;

			&--is-focused {
				// The `!important` annotation is relevant to also cover the `:active`
				// state, which would otherwise be overridden by inline styles.
				background-color: $select-focus-background-color !important;
				color: $selection-focus-color;
			}
		}

		&__multi-value {
			// The `!important` annotation is relevant to not be overridden by inline styles.
			border-radius: $tab-border-radius !important;
			border-color: var(
				--skin-color-primary-contrast-safe,
				$primary
			) !important;
			background-color: $tab-background-color-selected !important;

			&:hover {
				// The `!important` annotation is relevant to not be overridden by inline styles.
				border-color: var(
					--skin-color-primary-contrast-safe,
					$hover-primary
				) !important;
				background-color: $tab-background-color-selected-hover !important;

				.select__input__multi-value__label,
				.select__input__multi-value__remove {
					color: var(
						--skin-color-primary-contrast-safe,
						$hover-primary
					) !important;
				}
			}

			&__label {
				color: var(
					--skin-color-primary-contrast-safe,
					$primary
				) !important;
			}

			&__remove {
				color: var(
					--skin-color-primary-contrast-safe,
					$primary
				) !important;
			}
		}

		&--error {
			.select__input__control {
				border: 2px solid $form-error !important;

				// The `!important` annotation is relevant to also cover the `:active` and `:focus`
				// state, which would otherwise be overridden by inline styles.
			}
		}
	}

	&__option {
		padding: 14px;

		&:hover,
		:focus {
			background-color: $select-focus-background-color;
			color: var(--skin-color-default, $selection-focus-color);
		}

		&__icon {
			display: inline-block;
			position: relative;
			height: 24px;
			width: 24px;
			min-width: 24px;
			border-radius: 50%;
			background: $form-primary;
			color: $white;
			font-weight: $font-weight-bold;
			font-size: 9px;
			line-height: 24px;
			text-align: center;
			margin: 5px $grid-base 0 0;
			top: -4px;
		}

		&__label {
			display: inline-block;
			margin: 0 0 2px;
			vertical-align: bottom;
		}
	}

	&__error {
		margin: $grid-base $grid-base-three 0;

		p {
			color: $form-error;
		}
	}
}
