/* Global heading styles */
// TODO: delete global headline styles when every headline uses <Headline/>
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
	font-weight: $font-weight-medium;
}

h1 {
	font-size: $font-size-h1;
	line-height: 50px;
	margin-bottom: $grid-base-five;
}

h2 {
	font-size: $font-size-h2;
	line-height: 38px;
	margin-bottom: $grid-base-three;
}

h3 {
	font-size: $font-size-h3;
	line-height: 32px;
}

h4 {
	font-size: $font-size-h4;
	line-height: 26px;
}

h5 {
	font-size: $font-size-h5;
	line-height: 24px;
}

/* Global link styles */
a {
	color: var(--skin-color-primary-contrast-safe, $primary);
	text-decoration: none;
	transition: all 0.6s ease;

	&:hover {
		color: $link-hover-color-default;
		transition: all 0.3s ease;
	}
}

/* Global sup element */
sup {
	font-size: 75%;
}

/* Screen reader only */
.sr-only {
	border: 0 none;
	clip: rect(0px, 0px, 0px, 0px);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
