.banner {
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	margin: 0;
	padding: 0;
	z-index: 51;

	&__element {
		position: relative;
		background: $form-medium;
		color: $secondary;
		text-align: left;
		font-size: $font-size-primary;
		line-height: $line-height-primary;
		padding: $grid-base-two $grid-base-eight;
		margin: 0;
		top: 0;
		width: 100%;

		@include breakpoint($fromLarge) {
			padding: $grid-base-two 96px $grid-base-two $grid-base-eight;
		}

		a {
			text-decoration: underline;
			color: $white;
			&:hover {
				color: $hover-select;
			}
		}

		> svg {
			width: $grid-base-three;
			height: $grid-base-three;
			left: $grid-base-two;
			position: absolute;

			@include breakpoint($fromLarge) {
				left: $grid-base-three;
				top: 50%;
				transform: translate(0, -50%);
			}
		}

		button.close {
			border: unset;
			background-color: unset;
			position: absolute;
			top: $grid-base-two;
			right: $grid-base-two;

			@include breakpoint($fromLarge) {
				top: 50%;
				transform: translate(0, -50%);
				right: $grid-base-three;
			}
			svg {
				width: $grid-base-two;
				height: $grid-base-two;

				path {
					transition: fill 500ms ease;
					fill: var(--skin-color-secondary-contrast-safe, $secondary);
				}
			}
			&:hover {
				background-color: unset;
				cursor: pointer;

				svg {
					path {
						fill: $black;
					}
				}
			}
		}
	}
}

/* stylelint-disable-next-line selector-type-no-unknown, selector-no-vendor-prefix */
_:-ms-fullscreen,
:root .ieBanner[style] {
	/* stylelint-disable-next-line declaration-no-important */
	display: block !important;
}
